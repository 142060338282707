.dashboard {
  padding-left: 35%;
  padding-right: 35%;
}

.caption {
  text-transform: uppercase;
  font-size: 2.7rem;
  text-align: center;
  padding: 0.1rem;
}

.name {
  text-transform: uppercase;
  font-size: 2.3rem;
  font-weight: 400;
  color: #00468b;
  text-align: center;
}

.logoDashboard {
  padding-left: 12%;
  padding-right: 12%;
  padding-bottom: 1rem;
  padding-top: 1rem;
  background-color: transparent;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .dashboard {
    padding-left: 20%;
    padding-right: 20%;
  }

  .caption {
    font-size: 2.5rem;
  }

  .name {
    font-size: 2rem;
    font-weight: 500;
  }

  .logoDashboard {
    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .dashboard {
    padding-left: 5%;
    padding-right: 5%;
  }

  .caption {
    font-size: 2rem;
  }

  .name {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .logoDashboard {
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
}
