.Administration {
  padding-top: 6.25rem;
  padding-left: 15%;
  padding-right: 15%;
}

.Admin {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 0.9rem;
  background-color: #f5f5fd;
}

.Admin ul {
  background-color: #f5f5fd;
}

.Admin li {
  padding: 0.2rem;
  list-style: none;
  border-bottom: 0.5px solid #00468b;
}

.innerList li {
  border: none;
  padding: 0;
}

.navAdmin {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  min-height: 3rem;
  background-color: #f5f5fd;
  font-weight: 500;
  top: 0;
  margin-bottom: 4%;
  border: none;
  z-index: 0;
}

.navLinksAdmin {
  display: flex;
  justify-content: space-around;
  width: 80%;
  color: #00468b;
}

.navLinksAdmin ul {
  background-color: transparent;
}

.navLinksAdmin li {
  list-style: none;
}

.navLinksAdmin a {
  color: #00468b;
  text-decoration: none;
  letter-spacing: 0;
  font-size: 1rem;
}

.navLinksAdmin a:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1024px) {
  .Administration {
    padding-top: 5rem;
    padding-left: 8%;
    padding-right: 8%;
  }
}

@media screen and (max-width: 768px) {
  .Administration {
    padding-top: 3.75rem;
    padding-left: 1%;
    padding-right: 1%;
  }

  .navLinksAdmin a {
    font-size: 0.9rem;
  }
}
