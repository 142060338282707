footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f5f5fd;
  color: #00468b;
  text-align: center;
  min-height: 11rem;
  font-size: 0.8rem;
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  margin-top: 8rem;
}

footer li {
  list-style: none;
  padding: 0.2rem;
}

footer a {
  text-decoration: none;
  color: #00468b;
}

@media screen and (max-width: 768px) {
  footer {
    font-size: 0.7rem;
    min-height: 7rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
}
