nav {
  position: fixed;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  min-height: 3rem;
  background-color: white;
  font-weight: 500;
  top: 0;
  border-top: 2px solid white;
  border-bottom: 2px solid rgb(200, 15, 15);
  z-index: 9;
}

.navBar {
  transition: 0.5 ease-in;
}

.logo {
  background-color: transparent;
  margin-top: 0.188rem;
  margin-bottom: 0;
}

.logoImg {
  max-height: 3rem;
}

.logoImg:hover {
  opacity: 0.7;
  cursor: pointer;
}

.navLinks {
  display: flex;
  justify-content: space-around;
  width: 60%;
  color: #00468b;
}

.navLinks ul {
  background-color: transparent;
}

.navLinks li {
  list-style: none;
}

.linkHide {
  display: none;
}

.navLinks a {
  color: #00468b;
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 1rem;
}

.navLinks a:hover {
  opacity: 0.7;
}

.buttonLogout {
  background-color: white; /*rgb(245,165,50);*/
  color: rgb(200, 15, 15);
  font-weight: 600;
  border: none;
  padding: 0.2rem 0.1rem 0.1rem 0.1rem;
  cursor: pointer;
  float: right;
  margin-top: 0;
  letter-spacing: 1px;
  font-size: 0.95rem;
}

.burger {
  display: none;
  cursor: pointer;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: #00468b;
  margin: 5px;
  transition: all 0.3s ease;
}

@media screen and (max-width: 1024px) {
  .navLinks {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
  .navLinks {
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 30%;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
  }
  .navLinks a {
    font-size: 1.3rem;
  }
  .buttonLogout {
    margin-top: 1.5rem;
    font-size: 1.3rem;
    font-weight: 500;
  }
  .navHover:hover {
    color: #0a0a0a;
  }
  .burger {
    display: block;
  }
}

.nav-active {
  transform: translateX(0%);
  background-color: white;
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

.toggle .one {
  transform: rotate(-45deg) translate(-5px, 6px);
}
.toggle .two {
  opacity: 0;
}
.toggle .three {
  transform: rotate(45deg) translate(-5px, -6px);
}
