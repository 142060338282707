h1 {
  letter-spacing: 1px;
  color: #00468b;
  font-weight: 500;
  margin-top: 0.625rem;
  padding-top: 1.25rem;
  padding-bottom: 1rem;
}

h2 {
  letter-spacing: 1px;
  font-weight: 500;
  margin-top: 0.625rem;
  padding-top: 0.625rem;
  padding-bottom: 0.188rem;
}

.captionFilled {
  width: 100%;
  padding: 0.5rem;
  color: white;
  background-color: #00468b;
}

.viewSection {
  background-color: #f5f5fd;
  margin-bottom: 1rem;
}

.innerSection {
  padding: 0.5rem;
}

.labelSection {
  width: 50%;
  float: left;
}

.dataSection {
  margin-left: 0;
  float: left;
}

.full {
  width: 100%;
}

.boxList {
  border: 0.5px solid black;
  padding: 0.1rem;
  list-style-type: none;
}

.formForm {
  padding-top: 6.25rem;
  padding-left: 35%;
  padding-right: 35%;
}

.labelRL {
  width: 100%;
  display: block;
  font-size: 0.9rem;
  margin-top: 0.625rem;
  display: inline-block;
}

.labelHidden {
  visibility: hidden;
}

.inputRL {
  width: 100%;
  font-size: 0.9rem;
  height: 2rem;
  padding: 0.1rem;
  border: 1px solid black;
  resize: vertical;
  margin-top: 0.1rem;
  background-color: white;
}

.inputRL:hover {
  background-color: #f5f5fd;
}

.inputRL:focus {
  background-color: #f5f5fd;
}

.date {
  width: 100%;
}

.val {
  width: 100%;
  font-size: 0.9rem;
  height: 2rem;
  padding: 0.1rem;
  border: 1px solid black;
  resize: vertical;
  margin-top: 0.1rem;
  background-color: white;
}

.error {
  color: rgb(200, 15, 15);
  font-size: 0.7rem;
  font-weight: 600;
}

.errorMessage {
  width: 100%;
  padding: 0.5rem;
  color: white;
  background-color: rgb(200, 15, 15);
  font-size: 0.9rem;
}

.successMessage {
  width: 100%;
  padding: 0.5rem;
  color: white;
  background-color: #00468b;
  font-size: 0.9rem;
}

.link {
  color: #00468b;
}

.innerList li {
  border-bottom: 0;
  padding: 0;
}

.textareaRL {
  font-size: 1.1rem;
  height: 15rem;
}

.inputRLSubmit {
  background-color: #00468b;
  color: white;
  padding: 0.938rem 1.563rem;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  margin-right: 0.8rem;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.inputRLSubmit:hover {
  text-decoration: underline;
}

.small {
  font-size: 0.9rem;
  padding: 0.3rem 0.4rem;
  margin-top: 0;
  margin-left: 0.1rem;
  margin-bottom: 0.2rem;
}

.delete {
  background-color: rgb(200, 15, 15);
}

@media screen and (max-width: 1024px) {
  .formForm {
    padding-top: 5rem;
    padding-left: 22%;
    padding-right: 22%;
  }
}

@media screen and (max-width: 768px) {
  .formForm {
    padding-top: 3.75rem;
    padding-left: 3%;
    padding-right: 3%;
  }

  .inputRLSubmit {
    width: 100%;
  }

  .textareaRL {
    height: 7rem;
  }

  .textareaContact {
    height: 11rem;
  }

  .small {
    font-size: 0.6rem;
  }

  .inputRLSubmit {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}
