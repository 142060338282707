.myAppointments {
  padding-top: 6.25rem;
  padding-left: 10%;
  padding-right: 10%;
}

.medicalRecords {
  padding-top: 6.25rem;
  padding-left: 10%;
  padding-right: 10%;
}

.standardTable {
  border-collapse: collapse;
  margin: 1.563rem 0;
  font-size: 1rem;
  min-width: 100%;
}

.standardTable thead {
  background-color: grey;
  color: black;
  text-align: left;
  font-weight: bold;
}

.standardTable td {
  padding-top: 1%;
  padding-bottom: 1%;
}

.standardTable tbody tr {
  border-bottom: 0.5px solid grey;
}

.standardTable tbody tr:nth-of-type(even) {
  background-color: #f5f5fd;
}

.standardTable tbody tr:last-of-type {
  border-bottom: 2px solid grey;
}
