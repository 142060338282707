.medicalrecords {
  padding-top: 6.25rem;
  padding-left: 15%;
  padding-right: 15%;
  font-size: 0.9rem;
}

.medicalrecords ul {
  background-color: #f5f5fd;
}

.medicalrecords li {
  padding: 0.2rem;
  list-style: none;
  border-bottom: 0.5px solid #00468b;
}

.language {
  padding-bottom: 7rem;
}

.authors {
  padding-bottom: 4rem;
}

.members {
  padding-bottom: 10rem;
}

.innerList li {
  padding: 0;
  border: none;
}

.view {
  padding-left: 22%;
  padding-right: 22%;
}

.inside {
  display: flex;
}

.inside li {
  border-bottom: 0;
}

.tableHead {
  background-color: rgb(235, 105, 75);
  display: flex;
  font-weight: 600;
  height: 2rem;
}

.tableHead li {
  background-color: #00468b;
  font-size: 0.9rem;
  color: white;
  letter-spacing: 0.5px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border-bottom: 1.5px solid #00468b;
}

.spaceOne {
  width: 25%;
  padding: 0.2rem;
  font-weight: 600;
  overflow: hidden;
}
.spaceTwo {
  width: 20%;
  padding: 0.2rem;
  overflow: hidden;
}
.spaceThree {
  width: 44%;
  padding: 0.2rem;
  overflow: hidden;
}
.spaceFour {
  width: 11%;
  padding: 0.2rem;
  overflow: hidden;
}

.mobileOne {
  width: 80%;
  overflow: hidden;
}

.mobileTwo {
  width: 20%;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .medicalrecords {
    padding-top: 5rem;
    padding-left: 8%;
    padding-right: 8%;
  }
  .view {
    padding-left: 8%;
    padding-right: 8%;
  }

  .spaceOne {
    width: 25%;
  }
  .spaceTwo {
    width: 20%;
  }
  .spaceThree {
    width: 35%;
  }
  .spaceFour {
    width: 20%;
  }

  .mobileOne {
    width: 79%;
  }

  .mobileTwo {
    width: 21%;
  }
}

@media screen and (max-width: 768px) {
  .medicalrecords {
    padding-top: 3.75rem;
    padding-left: 1%;
    padding-right: 1%;
  }
  .view {
    padding-left: 1%;
    padding-right: 1%;
  }

  .tableHead li {
    font-size: 0.6rem;
  }

  .spaceOne {
    font-size: 0.8rem;
  }
  .spaceTwo {
    font-size: 0.6rem;
  }
  .spaceThree {
    font-size: 0.6rem;
  }
  .spaceFour {
    font-size: 0.6rem;
  }

  .mobileOne {
    width: 78%;
    font-size: 0.6rem;
  }

  .mobileTwo {
    width: 22%;
    font-size: 0.6rem;
  }
}
