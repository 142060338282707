* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  position: relative;
  margin: 0;
  padding-bottom: 16rem;
  min-height: 100%;
}
