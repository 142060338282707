.chat {
  width: 100%;
}

.formChat {
  width: 100%;
  padding: 0.3rem;
}

.textareaChat {
  padding-top: 5%;
}

.scrollChat {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 50vh;
}
